import { useEffect, useState } from "react";
import SlideToggle from "react-slide-toggle";
import DataTable from "react-data-table-component-footer";
import GlobalLeadsOptions from "../../Includes/Reports/GlobalLeadsOptions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/Reports/leadSummary.scss";
import Button from "../../UI/Button";
import { useGlobalLeads } from "../../../Hooks/Reports/useGlobalLeads";
import Loader from "../../Utility/Loader";
import { customStyles } from "../../../Constants/Reports/projectSettings";
import CustomTooltip from "../../../Constants/CustomTooltip";

const GlobalLeadsReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [optionsData, setOptionsData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tableColumnsAfterFormSubmit, setTableColumnsAfterFormSubmit] = useState([]);
  const [optionsDataAfterFormSubmit, setOptionsDataAfterFormSubmit] = useState({});
  const [checkSubmit, setCheckSubmit] = useState(false);
  const { manageGlobalLeads, isLoading } = useGlobalLeads();
  const [footer, setFooter] = useState({});

  const updatedColumns = tableColumns.map(column => ({
    ...column,
    cell: row => (
      <CustomTooltip text={row[column.selector]}>
        <span>{(row[column.selector] && row[column.selector].length > 20) ? row[column.selector].slice(0, 20) + '...' : row[column.selector]}</span>
      </CustomTooltip>
    )
  }));

  const handleStartDateChange = (date) => {
    if (date > endDate) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date) => {
    if (date < startDate) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setEndDate(date);
    }
  };
  useEffect(() => {
    if (Object.keys(optionsData).length > 0 && !checkSubmit) {
      console.log("option data");
      console.log(tableColumns);
      handleGlobalLeads(optionsData, tableColumns);
    }
  }, [optionsData])

  const handleSubmitButtonChange = () => {
    setLoader(true);
    setCheckSubmit(true);
    setOptionsData(optionsDataAfterFormSubmit);
    setTableColumns(tableColumnsAfterFormSubmit);
    if (Object.keys(optionsDataAfterFormSubmit).length > 0) {
      handleGlobalLeads(optionsDataAfterFormSubmit, tableColumnsAfterFormSubmit);
    }
  }
  const handleGlobalLeads = (options, columns) => {
    const requestData = {
      project_code:options.projectCode,
      start_date: convertDateTimeFormat(startDate),
      end_date: convertDateTimeFormat(endDate),
      filter_checker: (options.filterColumns || []).map(item => item.data_column),
      filter_values: (options.filterValues || [])
    };
    const columnValueObject = columns.reduce((acc, column) => {
      const matchingOption = options.data.find(option => option.attribute_slug === column.selector);
      if (matchingOption) {
        if (matchingOption.attribute_slug === 'uuid') {
          acc[matchingOption.attribute_slug] = 'uuid';
        }else{
          acc[column.selector] = matchingOption.attribute_dynamic_slug;
        }
      }
      return acc;
    }, {});
    requestData.column_value = columnValueObject;
    (async () => {
      const response = await manageGlobalLeads(requestData);
      setLoader(isLoading);
      console.log("global lead report data");
      setFilteredData(response.data);
      setCheckSubmit(false);
    })();
  }
  const convertDateTimeFormat = (dateTime) => {
    const date = new Date(dateTime);
    const convertedDate = date.toISOString().split('T')[0];
    return convertedDate;
  }
  return (
    <>
      <Loader active={loader} />
      <div className="lead-summary">
        <div className="col-lg-12 table-section project">
          <div className="row m-0">
            <div className="col-lg-12 col-md-12 p-1">
              <div className="tablesec">
                <div className="col-lg-12 py-3 px-3">
                  <div className="row">
                    <div className="col-lg-2 text-left">
                    </div>
                    <div className="col-lg-4" id="search_bar1">
                    </div>
                    <div className="col-lg-5"></div>
                  </div>
                </div>
                <SlideToggle
                  duration={1000}
                  collapsed={true}
                  whenReversedUseBackwardEase={false}
                  render={({ toggle, setCollapsibleElement }) => (
                    <div className="card mt-4">
                      <div className="card-header">
                        <button className="btn btn-primary" onClick={toggle}>
                          Options
                        </button>
                      </div>
                      <div className="card-body p-0" ref={setCollapsibleElement}>
                        <div className="p-3">

                          <GlobalLeadsOptions
                            setOptionsData={setOptionsData}
                            setTableColumns={setTableColumns}
                            setTableColumnsAfterFormSubmit={setTableColumnsAfterFormSubmit}
                            setOptionsDataAfterFormSubmit={setOptionsDataAfterFormSubmit}
                            setLoader={setLoader}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
                <div className="row m-t-20 date-picker">
                  <div className="col-md-4">
                    <div className="form-group"></div>
                  </div>
                  <div className="col-md-6">
                  <div className="row">
                    <div className="form-group">
                      <div className="input-daterange input-group" id="date-range">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          placeholderText="Start Date"
                          className="custom-datepicker form-control"
                          dateFormat="yyyy-MM-dd" 
                        />
                        <span className="input-group-addon">➔</span>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          placeholderText="End Date"
                          className="custom-datepicker form-control"
                          dateFormat="yyyy-MM-dd" 
                        />
                      </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <Button onClick={handleSubmitButtonChange} type="button" className="button_a button2" label="Submit" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table_hold">
                  <div className="table-container">
                    <DataTable
                      columns={updatedColumns }
                      data={filteredData.length > 0 ? filteredData : ''}
                      pagination
                      paginationPerPage={10}
                      footer={footer}
                      customStyles={customStyles}
                      columnResizeMode="expand"
                      resizableColumns 
                      showGridlines 
                      tableStyle={{ minWidth: '50rem' }}
                    />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GlobalLeadsReport






