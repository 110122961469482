import { useState, useEffect } from "react";
import { Data, Statistics, Calculations, Filter, Interval, ExcludeFilter } from "../../../Constants/Reports/ListOptions";
import { useLeadSummary } from "../../../Hooks/Reports/useLeadSummary";
// import { ProjectCode } from "../../../Constants/Reports/projectSettings";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";

const Options = (props) => {
  const [dataState, setDataState] = useState(Data);
  const [statisticsState, setStatisticsState] = useState(Statistics);
  const [calculationsState, setCalculationsState] = useState(Calculations);
  const [intervalState, setIntervalState] = useState(Interval);
  const [filterState, setFilterState] = useState(Filter);
  const [excludeFilterState, setExcludeFilterState] = useState(ExcludeFilter);
  const [viewData, setViewData] = useState();
  const [projectCode, setProjectCode] = useState([]);
  const [filterStateOptions, setFilterStateOptions] = useState({ data: '', status: 'd-none' });
  const [excludeFilterStateOptions, setExcludeFilterStateOptions] = useState({ data: '', status: 'd-none' });
  const [selectedFilterValues, setselectedFilterValues] = useState();
  const [selectedExcludeFilterValues, setselectedExcludeFilterValues] = useState();
  const [selectedProjectCode, setSelectedProjectCode] = useState([]);
  const { viewLeadSummary } = useLeadSummary();
  const { listProjectCode } = useProjectCodeInfo();

  const handleDataCheckboxChange = (key) => {
    setDataState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
  }
  const handleStatisticsCheckboxChange = (key) => {
    setStatisticsState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
  }
  const handleCalculationsCheckboxChange = (key) => {
    setCalculationsState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
  }
  const handleIntervalCheckboxChange = (key) => {
    setIntervalState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }))
  }
  const handleFilterCheckboxChange = (key) => {
    setFilterState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
    showFilterOptions(key);
  }
  const handleExcludeFilterCheckboxChange = (key) => {
    setExcludeFilterState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
    showExcludeFilterOptions(key);
  }
  const showFilterOptions = (key) => {
    console.log(key.data_column);
    if (viewData.hasOwnProperty(key) && filterState[key].checked == false) {
      const filterStateOptions = viewData[key].filter(name => name[0] !== "" && name[0] !== null && name.length > 0).map((name, index) => {
        return (
          <option value={name[0]} key={index}>{name[0]}</option>
        );
      });
      setFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { data: filterStateOptions, status: 'd-block' }
      }))
    } else {
      setFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { status: 'd-none' }
      }));
    }
  }
  const showExcludeFilterOptions = (key) => {
    if (viewData.hasOwnProperty(key) && excludeFilterState[key].checked == false) {
      const excludeFilterStateOptions = viewData[key].filter(name => name[0] !== "" && name[0] !== null && name.length > 0).map((name, index) => {
        return (
          <option value={name[0]} key={index}>{name[0]}</option>
        );
      });
      setExcludeFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { data: excludeFilterStateOptions, status: 'd-block' }
      }))
    } else {
      setExcludeFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { status: 'd-none' }
      }));
    }
  }
  const handleProjectCodeSelectChange = (event) => {
    // const selectedProjectCodeValues = Array.from(event.target.selectedOptions, option => option.value);
    // setProjectCode(selectedProjectCodeValues);
    const selectedProjectCodeValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedProjectCode(selectedProjectCodeValues);
    const updatedProjectCode = projectCode.filter(code => !selectedProjectCodeValues.includes(code));
    const newProjectCode = [...selectedProjectCodeValues, ...updatedProjectCode];
    setProjectCode(newProjectCode);
  }
  useEffect(() => {
    // const defaultProjectCode = [ProjectCode.length > 0 ? ProjectCode[0].value : ''];
   (async () => {
      const projectCodeList =  await listProjectCode();
      const reorderedProjects = projectCodeList.data.sort((a, b) => {
        if (a.project_code === "YLI-DEMO") return -1;
        if (b.project_code === "YLI-DEMO") return 1;
        return 0;
      });
      const projectCodes = reorderedProjects.map(project => project.project_code);
      setProjectCode(projectCodes);
      const defaultProjectCode = [projectCodes[0]];
      setSelectedProjectCode(defaultProjectCode);
      const response = await viewLeadSummary(defaultProjectCode);
      setViewData(response.data);
    //})();
    //setProjectCode(defaultProjectCode);
    const { data, tableColumns } = fetchCheckedOptionsValues();
    props.setTableColumns(tableColumns);
    props.setOptionsData({
      data: data.checkedDataOptions,
      statistics: data.checkedStatisticsOptions,
      calculations: data.checkedCalculationsOptions,
      interval: data.checkedIntervalOptions,
      filterColumns: data.checkedFilterOptions,
      filterValues: selectedFilterValues,
      excludeFilterColumns: data.checkedExcludeFilterOptions,
      excludeFilterValues: selectedExcludeFilterValues,
      projectCode : defaultProjectCode
    });
  })();
  }, []);
  useEffect(() => {
    const { data, tableColumns } = fetchCheckedOptionsValues();
    props.setTableColumnsAfterFormSubmit(tableColumns);
    props.setOptionsDataAfterFormSubmit({
      data: data.checkedDataOptions,
      statistics: data.checkedStatisticsOptions,
      calculations: data.checkedCalculationsOptions,
      interval: data.checkedIntervalOptions,
      filterColumns: data.checkedFilterOptions,
      filterValues: selectedFilterValues,
      excludeFilterColumns: data.checkedExcludeFilterOptions,
      excludeFilterValues: selectedExcludeFilterValues,
      projectCode : selectedProjectCode
    });
  }, [dataState, statisticsState, calculationsState, intervalState, filterState, excludeFilterState, selectedFilterValues, selectedExcludeFilterValues, selectedProjectCode])
  const fetchCheckedOptionsValues = () => {
    const checkedDataOptions = Object.values(dataState).filter(item => item.checked);
    const checkedStatisticsOptions = Object.values(statisticsState).filter(item => item.checked);
    const checkedCalculationsOptions = Object.values(calculationsState).filter(item => item.checked);
    const checkedIntervalOptions = Object.values(intervalState).filter(item => item.checked);
    const checkedFilterOptions = Object.values(filterState).filter(item => item.checked) ?? [];
    const checkedExcludeFilterOptions = Object.values(excludeFilterState).filter(item => item.checked) ?? [];
    const checkedOptions = checkedDataOptions.concat(checkedStatisticsOptions).concat(checkedIntervalOptions).concat(checkedCalculationsOptions);
    console.log("chceked options");
    console.log(checkedOptions);
    const tableColumns = checkedOptions.filter(item => item.value).map((item) => {
      if(item.data_column == 'splitname'){
        return {
          name:<div className="column-header">{item.value}</div>,
          selector: item.data_column,
          sortable: true,
          cell: row => <a href={row.split_path} target="_blank">{row.splitname}</a>
        }
      }else{
        return {
          name:<div className="column-header">{item.value}</div>,
          selector: item.data_column,
          sortable: true,
        }
      }
    
    });
    return { data: { checkedDataOptions, checkedStatisticsOptions, checkedCalculationsOptions, checkedIntervalOptions, checkedFilterOptions, checkedExcludeFilterOptions }, tableColumns };
  }

  const dataCheckboxes = Object.keys(dataState).map((key) => (

    <div key={`data-${key}`} className="checkbox-item">
      <label htmlFor={`data-checkbox-${key}`}>
        <input
          type="checkbox"
          id={`data-checkbox-${key}`}
          name={dataState[key].name}
          checked={dataState[key].checked}
          className="option-check-input"
          onChange={() => handleDataCheckboxChange(key)}
        />{" "}
        {dataState[key].value}
      </label>
    </div>
  ));
  const statisticsCheckboxes = Object.keys(statisticsState).map((key) => (
    <div key={`statistics-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`statistics-checkbox-${key}`}
        name={statisticsState[key].name}
        checked={statisticsState[key].checked}
        className="option-check-input"
        onChange={() => handleStatisticsCheckboxChange(key)}
      />{" "}
      <label htmlFor={`statistics-checkbox-${key}`}>{statisticsState[key].value}</label>
    </div>
  ));
  const calculationsCheckboxes = Object.keys(calculationsState).map((key) => (
    <div key={`statistics-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`calculations-checkbox-${key}`}
        name={calculationsState[key].name}
        checked={calculationsState[key].checked}
        className="option-check-input"
        onChange={() => handleCalculationsCheckboxChange(key)}
      />{" "}
      <label htmlFor={`calculations-checkbox-${key}`}>{calculationsState[key].value}</label>
    </div>
  ));
  const intervalCheckboxes = Object.keys(intervalState).map((key) => (
    <div key={`interval-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`interval-checkbox-${key}`}
        name={intervalState[key].name}
        checked={intervalState[key].checked}
        className="option-check-input"
        onChange={() => handleIntervalCheckboxChange(key)}
      />{" "}
      <label htmlFor={`interval-checkbox-${key}`}>{intervalState[key].value}</label>
    </div>
  ));
  const filterCheckboxes = Object.keys(Filter).map((key) => (
    <div key={`filter-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`filter-checkbox-${key}`}
        name={filterState[key].name}
        checked={filterState[key].checked}
        className="option-check-input"
        onChange={() => handleFilterCheckboxChange(key)}
      />{" "}
      <label htmlFor={`filter-checkbox-${key}`}>{filterState[key].value}</label>
    </div>
  ));
  const excludeFilterCheckboxes = Object.keys(ExcludeFilter).map((key) => (
    <div key={`filter-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`exclude-filter-checkbox-${key}`}
        name={excludeFilterState[key].name}
        checked={excludeFilterState[key].checked}
        className="option-check-input"
        onChange={() => handleExcludeFilterCheckboxChange(key)}
      />{" "}
      <label htmlFor={`exclude-filter-checkbox--${key}`}>{excludeFilterState[key].value}</label>
    </div>
  ));
  const handleFilterSelectChange = (event, type) => {
    const selectedFilterValues = Array.from(event.target.selectedOptions, option => option.value);
    setselectedFilterValues(prevState => ({
      ...prevState,
      [type]: selectedFilterValues
    }));
  }
  const handleExcludeFilterSelectChange = (event, type) => {
    const selectedExcludeFilterValues = Array.from(event.target.selectedOptions, option => option.value);
    setselectedExcludeFilterValues(prevState => ({
      ...prevState,
      [type]: selectedExcludeFilterValues
    }));
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  console.log("project codes");
  console.log(projectCode);
  return (
    <>
    <div class="alert alert-warning m-t-10">
        <p><strong>Note: FFE =  Leads / visitors * 100</strong></p>
       </div>
      <div className="row">
        <div className="col-md-1 control-label"><b>Project Code</b></div>
        <div className="col-md-4 col-sm-4 col-xs-4 checker">
          <select className="col-md-2 form-control" onChange={handleProjectCodeSelectChange}>
            {/* {listProjectCodes} */}
            {projectCode.map(project => (
              <option key={project} value={project}>{project}</option>
            ))}
          </select>
        </div>
      </div><br/>
      <div className="row">
        <div className="col-md-1 control-label"><b>Data</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{dataCheckboxes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Statistics</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{statisticsCheckboxes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Calculations</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{calculationsCheckboxes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Interval</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{intervalCheckboxes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Filter</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{filterCheckboxes}</div>
      </div><br />
      <div className="row">
        {Object.keys(filterState).map(key => (
          <div key={key} className={`col-md-4 div_domain ${filterStateOptions[key]?.status || 'd-none'}`}>
            {capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1').trim())}:
            <select className="col-md-2 form-control" multiple onChange={(event) => handleFilterSelectChange(event, filterState[key].data_column)}>
              <option>Select</option>
              {filterStateOptions[key]?.data || null}
            </select>
          </div>
        ))}
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Exclude Filter</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{excludeFilterCheckboxes}</div>
      </div><br />
      <div className="row">
        {Object.keys(excludeFilterState).map(key => (
          <div key={key} className={`col-md-4 div_domain ${excludeFilterStateOptions[key]?.status || 'd-none'}`}>
            {capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1').trim())}:
            <select className="col-md-2 form-control" multiple onChange={(event) => handleExcludeFilterSelectChange(event, excludeFilterState[key].data_column)}>
              <option>Select</option>
              {excludeFilterStateOptions[key]?.data || null}
            </select>
          </div>
        ))}
      </div>
    </>
  );
}
export default Options